// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
// components
// import Pagenation from 'components/Pagenation';
// api
import { getPayments } from 'utils/api';
import { useEffect, useState } from 'react';
import moment from 'moment';
import CustomDateRangePicker, { useDateRangePicker } from 'components/custom-date-range-picker';
import { fDate } from 'utils/format-time';
import { useBoolean } from 'hooks/use-boolean';
import { fBalance } from 'utils/utils';
import { useLocales } from 'locales';

type IBalanceHistory = {
    Id: number;
    OperationId: number;
    PlayerBonusId: string | number | null;
    BalanceBeforeWalletId: number;
    TransactionTypeName: string;
    Amount: number;
    Type: number;
    OperationTypeId: number;
    OperationTypeName: string;
    WalletType: number;
    WalletTypeName: string;
    BalanceBefore: number;
    BalanceAfter: number;
    CurrencyId: string | number | null;
    Kind: number;
    OperationTime: string | Date;
};

const Payments = () => {
    const { t } = useLocales();
    const [data, setData] = useState<IBalanceHistory[]>([]);
    const rangeInputPicker = useDateRangePicker(moment().add(-1, 'week').toDate(), new Date());
    const isLoading = useBoolean();

    const getData = async () => {
        isLoading.onTrue();
        try {
            const response = await getPayments({
                Timezone: 0,
                SearchDate: `${moment(rangeInputPicker.startDate).format('MM/DD/yyy')} - ${moment(rangeInputPicker.endDate).format('MM/DD/yyy')}`
            });
            setData(response.HistoryModel);
        } catch (error) {
            console.log(error);
        } finally {
            isLoading.onFalse();
        }
    };

    const dateModalClose = () => {
        rangeInputPicker.onClose();
        getData();
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <Stack>
            <Stack sx={{ width: '30%' }}>
                <TextField
                    sx={{ mt: 2 }}
                    variant="filled"
                    label={t('Date')}
                    onClick={rangeInputPicker.onOpen}
                    value={`${fDate(rangeInputPicker.startDate)} ~ ${fDate(rangeInputPicker.endDate)}`}
                />
            </Stack>
            <Box>
                <Table
                    size="small"
                    sx={{
                        minWidth: 960,
                        borderCollapse: 'separate',
                        borderSpacing: '0 16px',
                        borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)'
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Id')}</TableCell>
                            <TableCell>{t('Amount')}</TableCell>
                            <TableCell>{t('BalanceBefore')}</TableCell>
                            <TableCell>{t('BalanceAfter')}</TableCell>
                            <TableCell>{t('OperationType')}</TableCell>
                            <TableCell>{t('TransactionType')}</TableCell>
                            <TableCell>{t('Date')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflowX: 'auto' }}>
                        {isLoading.value && (
                            <TableRow>
                                <TableCell colSpan={7} align="center" sx={{ py: 6 }}>
                                    {t('loading')}
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoading.value && !data.length && (
                            <TableRow>
                                <TableCell colSpan={7} align="center" sx={{ py: 6 }}>
                                    {t('no_data')}
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoading.value &&
                            data.map((item: any) => (
                                <TableRow key={item.Id}>
                                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>{item.Id}</TableCell>
                                    <TableCell>{fBalance(item.Amount)}</TableCell>
                                    <TableCell>{fBalance(item.BalanceBefore)}</TableCell>
                                    <TableCell>{fBalance(item.BalanceAfter)}</TableCell>
                                    <TableCell>{item.OperationTypeName}</TableCell>
                                    <TableCell>
                                        {item.TransactionTypeName === 'back' ||
                                        item.TransactionTypeName === 'yes' ||
                                        item.TransactionTypeName === 'credit' ||
                                        item.TransactionTypeName === 'promo_credit' ? (
                                            <Button
                                                variant="contained"
                                                color="info"
                                                sx={{
                                                    fontSize: 12,
                                                    lineHeight: 1,
                                                    fontWeight: 600
                                                }}
                                            >
                                                {item.TransactionTypeName}
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    fontSize: 12,
                                                    lineHeight: 1,
                                                    fontWeight: 600
                                                }}
                                            >
                                                {item.TransactionTypeName}
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell>{fDate(item.OperationTime)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Box>
            <CustomDateRangePicker
                open={rangeInputPicker.open}
                startDate={rangeInputPicker.startDate}
                endDate={rangeInputPicker.endDate}
                onChangeStartDate={rangeInputPicker.onChangeStartDate}
                onChangeEndDate={rangeInputPicker.onChangeEndDate}
                onClose={dateModalClose}
                error={rangeInputPicker.error}
            />
            {/* <Pagenation
                count={totalRecords}
                rowsPerPage={parsedFilter.perPage}
                page={parsedFilter.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Stack>
    );
};

export default Payments;
