import type { FC } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { usePopover } from 'hooks/use-popover';
import { LanguagePopover } from './language-popover';

import { useLocales } from 'locales';

export const LanguageSwitch: FC = () => {
    const { currentLang } = useLocales();
    const popover = usePopover<HTMLButtonElement>();

    return (
        <>
            <Tooltip title="Language">
                <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
                    <Box
                        component="div"
                        sx={{
                            width: '26px',
                            height: '26px',
                            '& img': {
                                width: '100%'
                            },
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Box component="img" alt={currentLang.value} src={currentLang.flag} width={26} height={16} />
                    </Box>
                </IconButton>
            </Tooltip>
            <LanguagePopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
        </>
    );
};
