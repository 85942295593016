import { createContext, useCallback, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Settings, ThemeSettings } from 'types/setting';
import useLocalStorage from 'hooks/use-local-storage';

export interface SettingsContextValue {
    settings: Settings;
    drawerOpen: boolean; // only for dev
    isCustomized: boolean;
    handleDrawerOpen: (drawerOpen: boolean) => void;
    updateSettings: (update: Partial<Settings> | { language: string }) => Promise<void>;
}

const STORAGE_KEY = 'betim.settings';

export const themeSettings: ThemeSettings = {
    colorPreset: 'blue',
    layout: 'vertical',
    direction: 'rtl',
    navColor: 'blend-in',
    paletteMode: 'light',
    responsiveFontSizes: true,
    nav: true
};

const initialSettings: Settings = {
    language: 'he',
    changePasswordModal: false,
    forgotPasswordModal: false,
    loginModal: false,
    historyModal: false,
    registerModal: false,
    sportsbook: null,
    ...themeSettings
};

export const SettingsContext = createContext<SettingsContextValue>({
    settings: initialSettings,
    drawerOpen: false,
    isCustomized: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleDrawerOpen: () => {},
    updateSettings: () => Promise.resolve()
});

type SettingsProviderProps = {
    children: React.ReactNode;
};

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
    const [settings, setSettings] = useLocalStorage(STORAGE_KEY, initialSettings);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const isCustomized = useMemo(() => {
        return !isEqual(themeSettings, {
            colorPreset: settings.colorPreset,
            layout: settings.layout,
            navColor: settings.navColor,
            nav: settings.nav,
            directoin: settings.directoin,
            paletteMode: settings.paletteMode,
            responsiveFontSizes: settings.responsiveFontSizes
        });
    }, [settings]);

    const handleDrawerOpen = (drawerOpen: boolean) => {
        setDrawerOpen(drawerOpen);
    };

    const updateSettings = useCallback(
        async (updatedSettings: Partial<Settings>) => {
            setSettings({ ...settings, ...updatedSettings });
        },
        [settings, setSettings]
    );

    return (
        <SettingsContext.Provider
            value={{
                settings,
                updateSettings,
                isCustomized,
                drawerOpen,
                handleDrawerOpen
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export const SettingsConsumer = SettingsContext.Consumer;
