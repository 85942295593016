import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
//
import { allLangs, defaultLang } from './config-lang';
import useSettingContext from 'hooks/use-setting-context';

// ----------------------------------------------------------------------

export default function useLocales() {
    const { i18n, t } = useTranslation();

    const { settings, updateSettings } = useSettingContext();

    const currentLang = allLangs.find((lang) => lang.value === settings.language) || defaultLang;

    const onChangeLang = useCallback(
        (newlang: string) => {
            i18n.changeLanguage(newlang);
            localStorage.setItem('i18nextLng', newlang);
            const settingData: any = { language: newlang };
            if (newlang === 'he') {
                settingData.direction = 'rtl';
            } else {
                settingData.direction = 'ltr';
            }

            updateSettings(settingData);
        },
        [i18n, updateSettings]
    );

    return {
        allLangs,
        t,
        currentLang,
        onChangeLang
    };
}
