import { useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
// @mui
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider, ThemeOptions } from '@mui/material/styles';

import useSettingContext from 'hooks/use-setting-context';

import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
import RTL from './right-to-left';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import 'react-toastify/dist/ReactToastify.css';

import 'assets/style/index.css';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
    const { settings } = useSettingContext();
    const themeOptions = useMemo(
        () => ({
            palette: palette('dark'),
            shadows: shadows('dark'),
            customShadows: customShadows('dark'),
            typography,
            direction: settings.direction,
            spacing: 8,
            shape: { borderRadius: 4 }
        }),
        [settings.direction]
    );

    const theme = createTheme(themeOptions as ThemeOptions);

    theme.components = componentsOverrides(theme);

    return (
        <MuiThemeProvider theme={theme}>
            <RTL themeDirection={settings.direction}>
                <CssBaseline />
                <ToastContainer rtl={settings.direction === 'rtl'} theme="colored" closeOnClick />
                {children}
            </RTL>
        </MuiThemeProvider>
    );
}
