import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from 'utils/localStorageAvailable';
//
import { defaultLang } from './config-lang';
//
import translationEn from './langs/en.json';
import translationHe from './langs/he.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n.use(initReactI18next).init({
    resources: {
        en: { translations: translationEn },
        he: { translations: translationHe }
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
