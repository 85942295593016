import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'locales/i18n';

// Vercel Analytics
import { Analytics } from "@vercel/analytics/react"

// Declare Tawk_API with any type 
declare var Tawk_API: any;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const Index = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = 'https://embed.tawk.to/66cd987550c10f7a00a0d840/1i69hn2u8';
        script.async = true;
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        
        script.onload = () => {
            if (typeof Tawk_API !== "undefined") {
                console.log("Tawk.to script loaded successfully.");
                // You can add any code here that depends on Tawk_API being available
            }
        };

        document.body.appendChild(script);
    }, []);

    return <App />;
};

root.render(
    <React.StrictMode>
        <Analytics />
        <Index />
    </React.StrictMode>
);

reportWebVitals();