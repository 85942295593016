import { Link as ReactLink } from 'react-router-dom';
// @mui
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { alpha } from '@mui/material';
// config
import { TOP_NAV_HEIGHT } from 'config';
// hooks
import useAuthContext from 'hooks/use-auth-context';
import useSettingContext from 'hooks/use-setting-context';
// components
import { Logo } from 'components/logo';
import { BalanceButton } from './balance-button';
import { LanguageSwitch } from './language-switch';
import { useLocales } from 'locales';

const Header = () => {
    const { isLogined } = useAuthContext();
    const { updateSettings } = useSettingContext();
    const { t } = useLocales();

    return (
        <Stack
            component="header"
            sx={{
                backdropFilter: 'blur(6px)',
                backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.8),
                top: 0,
                width: '100%',
                position: 'fixed',
                zIndex: (theme) => theme.zIndex.appBar,
                boxShadow: (theme) => theme.shadows[10]
            }}
        >
            <Container maxWidth="lg">
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        minHeight: TOP_NAV_HEIGHT
                    }}
                >
                    <ReactLink to="/">
                        <Logo />
                    </ReactLink>
                    <Stack alignItems="center" direction="row" spacing={2} sx={{ ml: 'auto !important' }}>
                        {!isLogined && (
                            <Button
                                id="Button--sign-in"
                                onClick={() => updateSettings({ loginModal: true })}
                                variant="outlined"
                                color="secondary"
                                sx={{
                                    '&:hover': {
                                        boxShadow: '0 0 12px 0px #de2f78',
                                        textShadow: '0px 0px 10px #de2f78'
                                    }
                                }}
                            >
                                {t('Login')}
                            </Button>
                        )}
                        {isLogined && <BalanceButton />}
                        <LanguageSwitch />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};

export default Header;
