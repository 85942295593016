import { useRef } from 'react';
// @mui
import Box from '@mui/material/Box';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// config
import { BANNER_LIST } from 'config';

const BannerSlider = () => {
    const progressCircle = useRef<SVGSVGElement>(null);
    const progressContent = useRef<HTMLSpanElement>(null);

    const onAutoplayTimeLeft = (s: any, time: number, progress: number) => {
        if (progressCircle.current) {
            progressCircle.current.style.setProperty('--progress', `${1 - progress}`);
        }
        if (progressContent.current) {
            progressContent.current.textContent = `${Math.ceil(time / 1000)}`;
        }
    };

    return (
        <Box
            sx={{
                height: { md: 400, sm: 300, xs: 154 },
                overflow: 'hidden',
                '& .banner-swiper': {
                    '& .swiper-pagination-bullet': {
                        background: '#777b8a',
                        width: 12,
                        height: 12
                    },
                    height: '100%',
                    '& .swiper-pagination-bullet-active': {
                        background: '#fff'
                    }
                }
            }}
        >
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                pagination={{
                    clickable: true,
                    dynamicBullets: true
                }}
                className="banner-swiper"
            >
                {BANNER_LIST.map((img, i) => (
                    <SwiperSlide key={i}>
                        <Box
                            sx={{
                                height: '100%',
                                backgroundImage: `url(${img})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }}
                        />
                    </SwiperSlide>
                ))}
                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
            </Swiper>
        </Box>
    );
};

export default BannerSlider;
