// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
// api
import { getGamesHistory } from 'utils/api';
import { useEffect, useState } from 'react';
import moment from 'moment';
import CustomDateRangePicker, { useDateRangePicker } from 'components/custom-date-range-picker';
import { fDate } from 'utils/format-time';
import { useBoolean } from 'hooks/use-boolean';
import { fBalance } from 'utils/utils';
import { useLocales } from 'locales';

type IGameHistory = {
    Id: number;
    GameName: string;
    GameProviderId: number;
    GameVendorId: number;
    GameVendorName: string;
    Wager: number;
    Win: number;
    Date: string | Date;
};

const Games = () => {
    const { t } = useLocales();
    const [data, setData] = useState<IGameHistory[]>([]);
    const rangeInputPicker = useDateRangePicker(moment().add(-1, 'week').toDate(), new Date());
    const isLoading = useBoolean();

    const getData = async () => {
        isLoading.onTrue();
        try {
            const response = await getGamesHistory({
                Timezone: 0,
                SearchDate: `${moment(rangeInputPicker.startDate).format('MM/DD/yyy')} - ${moment(rangeInputPicker.endDate).format('MM/DD/yyy')}`
            });
            setData(response.Games);
        } catch (error) {
            console.log(error);
        } finally {
            isLoading.onFalse();
        }
    };

    const dateModalClose = () => {
        rangeInputPicker.onClose();
        getData();
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <Stack>
            <Stack sx={{ width: '30%' }}>
                <TextField
                    sx={{ mt: 2 }}
                    variant="filled"
                    label={t('Date')}
                    onClick={rangeInputPicker.onOpen}
                    value={`${fDate(rangeInputPicker.startDate)} ~ ${fDate(rangeInputPicker.endDate)}`}
                />
            </Stack>
            <Box>
                <Table
                    size="small"
                    sx={{
                        minWidth: 960,
                        borderCollapse: 'separate',
                        borderSpacing: '0 16px',
                        borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)'
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Id')}</TableCell>
                            <TableCell>{t('Wager')}</TableCell>
                            <TableCell>{t('Win')}</TableCell>
                            <TableCell>{t('game_name')}</TableCell>
                            <TableCell>{t('vendor_name')}</TableCell>
                            <TableCell>{t('Date')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflowX: 'auto' }}>
                        {isLoading.value && (
                            <TableRow>
                                <TableCell colSpan={6} align="center" sx={{ py: 6 }}>
                                    {t('loading')}
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoading.value && !data.length && (
                            <TableRow>
                                <TableCell colSpan={6} align="center" sx={{ py: 6 }}>
                                    {t('no_data')}
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoading.value &&
                            data.map((item: any) => (
                                <TableRow key={item.Id}>
                                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>{item.Id}</TableCell>
                                    <TableCell>{fBalance(item.Wager)}</TableCell>
                                    <TableCell>{fBalance(item.Win)}</TableCell>
                                    <TableCell>{item.GameName}</TableCell>
                                    <TableCell>{item.GameVendorName}</TableCell>
                                    <TableCell>{fDate(item.Date)}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Box>
            <CustomDateRangePicker
                open={rangeInputPicker.open}
                startDate={rangeInputPicker.startDate}
                endDate={rangeInputPicker.endDate}
                onChangeStartDate={rangeInputPicker.onChangeStartDate}
                onChangeEndDate={rangeInputPicker.onChangeEndDate}
                onClose={dateModalClose}
                error={rangeInputPicker.error}
            />
        </Stack>
    );
};

export default Games;
