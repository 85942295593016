import merge from 'lodash/merge';

// import { enUS as enUSAdapter, arSA as arSAAdapter } from 'date-fns/locale';
// core
import { enUS as enUSCore, arSA as arSACore } from '@mui/material/locale';
// date-pickers
import { enUS as enUSDate } from '@mui/x-date-pickers/locales';
// data-grid
import { enUS as enUSDataGrid, arSD as arSDDataGrid } from '@mui/x-data-grid';

import gbFlag from 'assets/img/flags/en.svg';
import ilFlag from 'assets/img/flags/il.png';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
        // adapterLocale: enUSAdapter,
        flag: gbFlag
    },
    {
        label: 'Hebrew',
        value: 'he',
        systemValue: merge(arSDDataGrid, arSACore),
        // adapterLocale: arSAAdapter,
        flag: ilFlag
    }
];

export const defaultLang = allLangs[1]; // English
