import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import MenuList from 'components/MenuList';
import { LoadingScreen } from 'components/loading-screen';
import BannerSlider from 'components/BannerSlider';
import { useParams } from 'routes/hook';


const MainLayout = () => {
    const { gameId } = useParams();

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Header />
            <Box
                component="main"
                sx={{
                    mt: '64px',
                    minHeight: 'calc(100vh - 72px - 347px)'
                }}
            >
                {!gameId && <BannerSlider />}
                <MenuList />
                <Outlet />
            </Box>
            <Footer />
        </Suspense>
    );
};

export default MainLayout;
