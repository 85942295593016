// Dispatch
import { createSlice } from '@reduxjs/toolkit';
// @types
import { IUserState } from 'types/user';

// ----------------------------------------------------------------------

const initialState: IUserState = {
    authLoading: true,
    isLogined: false,
    user: null,
    balance: 0,
    isAgent: false,
    agentToken: '',
    sportsToken: ''
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginAction(state, action) {
            state.isLogined = true;
            state.authLoading = false;
            state.user = action.payload.user;
            state.isAgent = action.payload.isAgent;
            state.agentToken = action.payload.agentToken;
        },
        logoutAction(state) {
            state.isLogined = false;
            state.authLoading = false;
            state.isAgent = false;
            state.agentToken = '';
            state.user = null;
            state.balance = 0;
        },
        updateBalance(state, action) {
            state.balance = action.payload.balance;
        },
        updateUserAction(state, action) {
            state.user = { ...state.user, ...action.payload };
            state.isAgent = action.payload.isAgent;
            state.agentToken = action.payload.agentToken;
        },
        updateSportsToken(state, action) {
            state.sportsToken = action.payload.token;
        }
    }
});

export const { loginAction, logoutAction, updateBalance, updateUserAction } = authSlice.actions;
// Reducer
export default authSlice.reducer;
