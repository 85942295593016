import { useContext } from 'react';
//
import { SettingsContext } from 'context/setting/setting-context';

// ----------------------------------------------------------------------

export default function useSettingContext() {
    const context = useContext(SettingsContext);

    if (!context) throw new Error('useSettingContext context must be use inside SettingProvider');

    return context;
}
