import type { FC } from 'react';
import { Box, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';

import { useLocales } from 'locales';

interface LanguagePopoverProps {
    anchorEl: null | Element;
    onClose?: () => void;
    open?: boolean;
}

export const LanguagePopover: FC<LanguagePopoverProps> = (props) => {
    const { allLangs, onChangeLang } = useLocales();
    const { anchorEl, onClose, open = false, ...other } = props;

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
            }}
            disableScrollLock
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            onClose={onClose}
            open={open}
            slotProps={{
                paper: { sx: { width: 220, p: 1 } }
            }}
            {...other}
        >
            {allLangs.map((langItem) => (
                <MenuItem key={langItem.value} sx={{ borderRadius: 0.5 }} onClick={() => onChangeLang(langItem.value)}>
                    <ListItemIcon>
                        <Box
                            component="div"
                            sx={{
                                width: 26,
                                height: 26,
                                '& img': {
                                    width: '100%'
                                },
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Box component="img" alt={langItem.label} src={langItem.flag} width={26} height={16} />
                        </Box>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle2">{langItem.label}</Typography>} />
                </MenuItem>
            ))}
        </Popover>
    );
};
