import { createContext, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'utils/axios';
import localStorageAvailable from 'utils/localStorageAvailable';

import { loginAction, logoutAction, updateUserAction, updateBalance } from 'store/slices/auth';
import useSettingContext from 'hooks/use-setting-context';
import { LOCAL_SPORT_KEY } from 'config';
import { IUserAccountGeneral } from 'types/user';

export type AuthContextValue = {
    authLoading: boolean;
    isLogined: boolean;
    user: IUserAccountGeneral;
    isAgent: boolean;
    agentToken: string;
    login: (username: string, password: string) => Promise<void>;
    updateUser: (params: { kyc: boolean }) => Promise<void>;
    logout: () => void;
};

export const AuthContext = createContext<AuthContextValue | null>(null);

type AuthProviderProps = {
    children: React.ReactNode;
};

const LOCAL_KEY = 'BETIM';

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const { updateSettings } = useSettingContext();
    const dispatch = useDispatch();
    const store = useSelector((state: any) => state.auth);
    const storageAvailable = localStorageAvailable();

    const getBalance = async (userId: number, balance: number) => {
        try {
            const response = await axios.post(
                `/common/getinfo`,
                {},
                {
                    params: {
                        id: userId,
                        gameId: 1
                    },
                    withCredentials: true
                }
            );
            const data = response.data;
            if (data.LogOut === '1') {
                dispatch(logoutAction());
            } else {
                if (balance !== data.Balance) {
                    dispatch(updateBalance({ balance: data.Balance }));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const initialize = async () => {
        try {
            const stringUser = storageAvailable ? localStorage.getItem(LOCAL_KEY) : null;
            if (stringUser) {
                const userData = JSON.parse(stringUser);
                const response = await axios.post(
                    `/common/getinfo`,
                    {},
                    {
                        params: {
                            id: userData.user.Id,
                            gameId: 1
                        },
                        withCredentials: true
                    }
                );
                const data = response.data;

                if (data.LogOut === '1') {
                    dispatch(logoutAction());
                    localStorage.removeItem(LOCAL_SPORT_KEY);
                } else {
                    dispatch(
                        loginAction({ user: userData.user, isAgent: userData.isAgent, agentToken: userData.agentToken })
                    );
                    dispatch(updateBalance({ balance: data.Balance }));
                }
            } else {
                dispatch(logoutAction());
                localStorage.removeItem(LOCAL_SPORT_KEY);
            }
        } catch (error) {
            dispatch(logoutAction());
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (store.user) {
                getBalance(store.user.Id, store.balance);
            }
        }, 5000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [store.user, store.balance]);

    useEffect(() => {
        initialize();
        // eslint-disable-next-line
    }, []);

    // LOGIN
    const login = useCallback(
        async (username: string, password: string) => {
            const response = await axios.post(
                '/api/login',
                {
                    Username: username,
                    Password: password
                },
                {
                    withCredentials: true
                }
            );
            const userData = response.data;
            const gameInfoResponse = await axios.post(
                '/common/getinfo',
                {},
                {
                    params: {
                        id: userData.Player.Id,
                        gameId: 1
                    },
                    withCredentials: true
                }
            );
            try {
                const tokenResponse = await axios.post(
                    '/api/betby/GetToken',
                    {},
                    {
                        withCredentials: true
                    }
                );
                if (tokenResponse.data.token) {
                    localStorage.setItem(LOCAL_SPORT_KEY, tokenResponse.data.token);
                }
            } catch (error) {
                console.log(error);
            }
            dispatch(
                loginAction({ user: userData.Player, isAgent: userData.isAgent, agentToken: userData.agentToken })
            );
            if (gameInfoResponse.data && gameInfoResponse.data.Balance) {
                dispatch(updateBalance({ balance: gameInfoResponse.data.Balance }));
            }
            updateSettings({ loginModal: false });
            localStorage.setItem(
                LOCAL_KEY,
                JSON.stringify({ user: userData.Player, isAgent: userData.isAgent, agentToken: userData.agentToken })
            );
        },
        [dispatch, updateSettings]
    );

    // UPDATE USER
    const updateUser = useCallback(
        async (params: { kyc: boolean }) => {
            dispatch(updateUserAction(params));
        },
        [dispatch]
    );

    // LOGOUT
    const logout = useCallback(async () => {
        try {
            localStorage.removeItem(LOCAL_KEY);
            localStorage.removeItem(LOCAL_SPORT_KEY);
            dispatch(logoutAction());
            await axios.post('/api/account/LogOut', {}, { withCredentials: true });
            window.location.href = '/';
        } catch (error) {
            dispatch(logoutAction());
            window.location.href = '/';
        }
    }, [dispatch]);

    const memoizedValue = useMemo(
        () => ({
            authLoading: store.authLoading,
            user: store.user,
            blockList: store.blockList,
            disabledMatch: store.disabledMatch,
            isAgent: store.isAgent,
            agentToken: store.agentToken,
            stakes: store.stakes,
            isLogined: store.isLogined,
            login,
            logout,
            updateUser
        }),
        [store, login, logout, updateUser]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};
