import axios from './axios';
import Axios from 'axios';

export const getGamesMobile = async (params: any) => {
    const response = await axios.post(`/api/common/getGamesMobile`, params, { withCredentials: true });
    return response.data;
};

export const getLaunch = async (gameId: string) => {
    const response = await axios.get(`/api/common/Launch`, {
        params: {
            type: 'real',
            mode: 'frame',
            id: gameId,
            slug: 'play'
        },
        withCredentials: true
    });
    return response.data;
};

export const changePassword = async (params: any) => {
    const response = await axios.post(`/api/account/ChangePassword`, params, { withCredentials: true });
    return response.data;
};

export const getPayments = async (params: any) => {
    const response = await axios.post(`/api/account/PlayerBalanceHistory?timeZone=0`, params, {
        withCredentials: true
    });
    return response.data;
};

export const getTransactions = async (params: any) => {
    const response = await axios.post(`/api/account/TransactionsHistory?timeZone=0`, params, {
        withCredentials: true
    });
    return response.data;
};

export const getGamesHistory = async (params: any) => {
    const response = await axios.post(`/api/account/GameHistory?timeZone=0&productId=1`, params, {
        withCredentials: true
    });
    return response.data;
};

export const getSportsToken = async () => {
    const response = await axios.post(`/api/betby/GetToken`, {}, { withCredentials: true });
    return response.data;
};

export const getCasinoLimits = async (params: any) => {
    const response = await Axios.post(`https://agents-api.betways.io/gaming/getCasinoLimits`, params);
    return response.data;
};

export const getCaptcha = async () => {
    const response = await axios.get(`/common/captcharefresh`);
    return response.data;
};

export const forgotPassword = async (params: any) => {
    const response = await axios.get(`/api/home/forgotpass`, params);
    return response.data;
};
