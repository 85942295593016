import { useState, type FC } from 'react';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//
import { Wallet } from '@mui/icons-material';
import { Icon } from '@iconify/react';
//
import useSettings from 'hooks/use-setting-context';
import useAuthContext from 'hooks/use-auth-context';
//
import { StyledPopover } from 'components/Styles';
//
import { useSelector } from 'store/store';
import { fBalance } from 'utils/utils';
import { useLocales } from 'locales';

export const BalanceButton: FC = () => {
    const { user, isAgent, agentToken, logout } = useAuthContext();
    const { updateSettings } = useSettings();
    const { t } = useLocales();

    const { balance } = useSelector((state: any) => state.auth);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    const anchorOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleHitory = () => {
        updateSettings({ historyModal: true });
        setOpen(false);
    };

    const handlePassword = () => {
        updateSettings({ changePasswordModal: true });
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            logout();
            setOpen(false);
            setAnchorEl(null);
        } catch (error: any) {
            const message = typeof error === 'string' ? error : error.message;
            console.log(message);
        }
    };

    const openLoginGaent = async () => {
        const url = `https://agents.betim.io/webLogin?token=${agentToken}`;
        window.open(url, '_blank');
    };

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems={'center'}>
                <Wallet />
                <Typography variant="subtitle2" textAlign={'center'} fontWeight={700}>
                    {fBalance(balance)}
                </Typography>
            </Stack>

            {isAgent && (
                <Button variant="outlined" onClick={openLoginGaent}>
                    {t('Agents')}
                </Button>
            )}

            <IconButton onClick={anchorOpen}>
                <Icon icon="solar:user-bold" color="white" />
            </IconButton>
            <StyledPopover
                sx={{ '& .MuiPopover-paper': { bgcolor: 'background.paper', p: 0 } }}
                id="user-menu-list"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleClose}
            >
                <Stack>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <>
                                <ListSubheader
                                    component="div"
                                    sx={{ display: 'flex', alignItems: 'center', py: 1, bgcolor: 'secondary.main' }}
                                >
                                    <Icon icon="mingcute:user-4-fill" color="white" width="24" height="24" />
                                    <Typography sx={{ fontSize: 14, fontWeight: 600, ml: 1, color: 'text.primary' }}>
                                        {user.UserName}
                                    </Typography>
                                </ListSubheader>
                                <ListSubheader
                                    component="div"
                                    sx={{ display: 'flex', alignItems: 'center', py: 1, bgcolor: 'secondary.main' }}
                                >
                                    <Wallet sx={{ color: 'white' }} />
                                    <Typography sx={{ fontSize: 14, fontWeight: 600, ml: 1, color: 'text.primary' }}>
                                        {fBalance(balance)}
                                    </Typography>
                                </ListSubheader>
                            </>
                        }
                    >
                        <ListItemButton
                            onClick={handleHitory}
                            sx={{
                                minWidth: 200,
                                '& .MuiListItemText-primary': {
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: 'text.secondary',
                                    textTransform: 'capitalize'
                                },
                                '& svg': {
                                    color: 'text.secondary'
                                },
                                '&:hover': {
                                    '& svg': {
                                        color: 'text.primary'
                                    },
                                    '& .MuiListItemText-primary': {
                                        color: 'text.primary'
                                    }
                                }
                            }}
                        >
                            <ListItemIcon>
                                <Icon icon="solar:bill-list-bold-duotone" width="24" height="24" />
                            </ListItemIcon>
                            <ListItemText primary={t('History')} />
                        </ListItemButton>
                        <ListItemButton
                            onClick={handlePassword}
                            sx={{
                                minWidth: 200,
                                '& .MuiListItemText-primary': {
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: 'text.secondary',
                                    textTransform: 'capitalize'
                                },
                                '& svg': {
                                    color: 'text.secondary'
                                },
                                '&:hover': {
                                    '& svg': {
                                        color: 'text.primary'
                                    },
                                    '& .MuiListItemText-primary': {
                                        color: 'text.primary'
                                    }
                                }
                            }}
                        >
                            <ListItemIcon>
                                <Icon icon="solar:lock-password-bold-duotone" width="24" height="24" />
                            </ListItemIcon>
                            <ListItemText primary={t('change_password')} />
                        </ListItemButton>
                        <ListItemButton
                            onClick={handleLogout}
                            sx={{
                                minWidth: 200,
                                '& .MuiListItemText-primary': {
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: 'text.secondary',
                                    textTransform: 'capitalize'
                                },
                                '& svg': {
                                    color: 'text.secondary'
                                },
                                '&:hover': {
                                    '& svg': {
                                        color: 'text.primary'
                                    },
                                    '& .MuiListItemText-primary': {
                                        color: 'text.primary'
                                    }
                                }
                            }}
                        >
                            <ListItemIcon>
                                <Icon icon="solar:logout-3-bold" width="24" height="24" />
                            </ListItemIcon>
                            <ListItemText primary={t('Logout')} />
                        </ListItemButton>
                    </List>
                </Stack>
            </StyledPopover>
        </Stack>
    );
};
