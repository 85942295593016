import { lazy } from 'react';
// layouts
import MainLayout from 'layouts/HomeLayout';
// ----------------------------------------------------------------------
// OVERVIEW
const HomePage = lazy(() => import('pages/home'));
const SlotsPage = lazy(() => import('pages/slots'));
const LiveCasino = lazy(() => import('pages/live-casino'));
const GamePage = lazy(() => import('pages/Game'));
// layouts

// ----------------------------------------------------------------------

export const mainRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <HomePage />
            },
            {
                path: 'slots',
                element: <SlotsPage />
            },
            {
                path: 'slots/:gameId',
                element: <GamePage />
            },
            {
                path: 'live-casino',
                element: <LiveCasino />
            },
            {
                path: 'live-casino/:gameId',
                element: <GamePage />
            }
        ]
    }
];
